import { useMemo, useState } from "react";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import "@fontsource/roboto";
import { UserContext } from "./UserContext";
import Layout from "./components/Layout";
import Loading from "./components/Loading";
import Home from "./pages/user/Home";
import Login from "./pages/user/Login";
import Signup from "./pages/user/Signup";
import ResetPassword from "./pages/user/ResetPassword";
import AML from "./pages/AML/AML";
import Accounting from "./pages/accounting/Accounting";
import Finance from "./pages/finance/Finance";
import CustomerService from "./pages/customer/CustomerService";
import UserManagement from "./pages/admins/UserManagement";

const theme = createTheme({
    palette: {
        primary: {
            main: "#FF8E15",
        },
        secondary: {
            main: "#6C757D",
        },
        background: {
            //default: "#FF00FFFF"
            // default: "#F8F9FA",
            default: "#fff",
        },
        text: {
            primary: "#070707",
            secondary: "#6C757D",
        },
        info: {
            main: "#fff",
        },
    },
    typography: {
        fontFamily: "roboto",
        fontWeightLight: 100,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        fontWeightBold: 900,
    },
});

function App() {
    const [navTitle, setNavTitle] = useState("");
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState(null);
    const value = useMemo(
        () => ({
            navTitle,
            setNavTitle,
            loading,
            setLoading,
            user,
            setUser,
        }),
        [navTitle, loading]
    );

    return (
        <ThemeProvider theme={theme}>
            <Router>
                <UserContext.Provider value={value}>
                    <CssBaseline />

                    <Layout>
                        <Routes>
                            <Route
                                exact
                                path='/login'
                                element={<Login />}
                            />
                            <Route
                                exact
                                path='/register'
                                element={<Signup />}
                            />
                            <Route
                                exact
                                path='/resetPassword'
                                element={<ResetPassword />}
                            />
                            <Route
                                exact
                                path='/'
                                element={<Home />}
                            />
                            <Route
                                exact
                                path='/aml'
                                element={<AML />}
                            />
                            <Route
                                exact
                                path='/accounting'
                                element={<Accounting />}
                            />
                            <Route
                                exact
                                path='/finance'
                                element={<Finance />}
                            />
                            <Route
                                exact
                                path='/customer'
                                element={<CustomerService />}
                            />
                            <Route
                                exact
                                path='/users'
                                element={<UserManagement />}
                            />
                        </Routes>
                    </Layout>

                    {/* Loading */}
                    <Loading enabled={loading} />
                </UserContext.Provider>
            </Router>
        </ThemeProvider>
    );
}

export default App;
