import React, { useContext, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Box, Container, Avatar } from "@mui/material";
import { styled } from "@mui/system";
import KeyIcon from "@mui/icons-material/Key";
import PasswordChecklist from "react-password-checklist";
import secureLocalStorage from "react-secure-storage";

import { UserContext } from "../../UserContext";
import { useEffectOnce } from "../../utils/UseEffectOnce";
import { AdminsAPI } from "../../apis/AdminsAPI";
import { Label } from "../../components/Label";
import Panel from "../../components/Panel";
import TextInput from "../../components/TextInput";
import RoundCornerButton from "../../components/Button";
import Gap from "../../components/Gap";
import AlertMessage from "../../components/Alert";
import CopyrightBar from "../../components/CopyrightBar";

const Background = styled("div")(() => ({
    height: "90vh",
    backgroundImage: "url(/images/background.jpg)",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
}));

export default function ResetPassword() {
    const navigate = useNavigate();
    const location = useLocation();

    const { setLoading } = useContext(UserContext);
    const [alert, setAlert] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const passwordIsValid = useRef(false);

    useEffectOnce(() => {
        const queryUrl = location.search;
        const queryParams = new URLSearchParams(queryUrl);
        const accessToken = queryParams.get("token");
        // console.log("accessToken", accessToken);

        if (accessToken) {
            secureLocalStorage.setItem("accessToken", accessToken);
        }
    });

    const checkInputData = () => {
        if (password === "") {
            return "Please enter password";
        } else if (!passwordIsValid.current) {
            return "Invalid password";
        } else if (confirmPassword === "") {
            return "Please enter confirm password";
        } else if (confirmPassword !== password) {
            return "The password confirmation does not match.";
        }
        return null;
    };

    const handleResetPassword = async () => {
        const alertMsg = checkInputData();
        if (alertMsg) {
            setAlert(alertMsg);
        } else {
            setLoading(true);
            const user = await AdminsAPI.resetPassword({
                password: password,
            });
            setLoading(false);

            if (user) {
                navigate("/login");
            } else {
                setAlert("Reset password fail");
            }
        }
    };

    const PasswordRules = () => {
        return (
            <Box
                sx={{
                    marginTop: "30px",
                    marginLeft: "8px",
                    marginBottom: "8px",
                }}
            >
                <PasswordChecklist
                    rules={["minLength", "specialChar", "number", "capital"]}
                    minLength={8}
                    value={password}
                    // valueAgain={confirmPassword}
                    onChange={(isValid) => {
                        // console.log(isValid, password);
                        passwordIsValid.current = isValid;
                    }}
                    style={{ fontSize: "10px", marginTop: "-20px" }}
                    iconSize={12}
                />
            </Box>
        );
    };

    return (
        <Background>
            <Box
                display='flex'
                justifyContent='center'
                sx={{ marginTop: "50px" }}
            >
                <Avatar
                    sx={{
                        width: 50,
                        height: 50,
                        bgcolor: "#FF8E1533",
                    }}
                >
                    <KeyIcon color='primary' />
                </Avatar>
            </Box>

            <Label
                fontSize='25px'
                color='#777'
                sx={{
                    marginTop: "18px",
                    marginBottom: "18px",
                    textAlign: "center",
                }}
            >
                Reset Password
            </Label>

            <Container maxWidth='xs'>
                <Panel>
                    <TextInput
                        label='Password'
                        placeholder='Enter password'
                        gutterBottom
                        type='password'
                        value={password}
                        setValue={setPassword}
                        tip={password !== "" && <PasswordRules />}
                    />

                    <TextInput
                        label='Confirm Password'
                        placeholder='Confirm password'
                        gutterBottom
                        type='password'
                        value={confirmPassword}
                        setValue={setConfirmPassword}
                    />

                    <RoundCornerButton onClick={handleResetPassword}>
                        Reset
                    </RoundCornerButton>
                </Panel>

                <Gap height='30px' />
            </Container>

            <CopyrightBar />

            {/* Alert */}
            <AlertMessage alertState={{ alert, setAlert }} />
        </Background>
    );
}
