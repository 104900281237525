import React from "react";
import { Box, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";

const sxBottomPanel = {
    position: "absolute",
    width: "100%",
    height: 40,
    bottom: 0,
    backgroundColor: "#f0f0f0",
};

export default function CopyrightBar() {
    return (
        <div>
            <Box
                sx={sxBottomPanel}
                display='flex'
                justifyContent='center'
                alignItems='center'
            >
                <Typography
                    variant='body2'
                    color={grey[500]}
                >
                    Copyright © 2022 opcryptoex
                </Typography>
            </Box>
        </div>
    );
}
