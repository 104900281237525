import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
    Menu,
    MenuItem,
    Fade,
    ListItemIcon,
    ListItemText,
} from "@mui/material";
import { UserContext } from "../UserContext";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";

export default function UserMenu({ userMenuState }) {
    const navigate = useNavigate();
    const { setUser } = useContext(UserContext);
    const { userMenu, setUserMenu } = userMenuState;
    const openSignOut = Boolean(userMenu);

    const handleSettings = () => {};

    const handleSignOut = () => {
        setUserMenu(null);
        setUser(null);
        navigate("/login");
    };

    return (
        <Menu
            anchorEl={userMenu}
            open={openSignOut}
            onClose={() => {
                setUserMenu(null);
            }}
            TransitionComponent={Fade}
        >
            <MenuItem
                sx={{ color: "text.secondary" }}
                onClick={handleSettings}
            >
                <ListItemIcon>
                    <SettingsIcon fontSize='small' />
                </ListItemIcon>
                <ListItemText>Settings</ListItemText>
            </MenuItem>

            <MenuItem
                sx={{ color: "text.secondary" }}
                onClick={handleSignOut}
            >
                <ListItemIcon>
                    <LogoutIcon fontSize='small' />
                </ListItemIcon>
                <ListItemText>Logout</ListItemText>
            </MenuItem>
        </Menu>
    );
}
