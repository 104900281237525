import {
    Box,
    Container,
    Grid,
    TextField,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import React, { useState, useContext, useRef, useLayoutEffect } from "react";
import Panel from "../../components/Panel";
import { TitleLabel } from "../../components/Label";
import AlertMessage from "../../components/Alert";
import Authorizer from "../../utils/Authorizer";
import CustomerInfo from "./CustomerInfo";
import { CustomerAPI } from "../../apis/CustomerAPI";
import { UserContext } from "../../UserContext";
import { useLocation } from "react-router-dom";

const columns = [
    { id: "chineseName", label: "姓名", minWidth: 100, align: "center" },
    { id: "id", label: "會員UID", minWidth: 100, align: "center" },
    {
        id: "birthDate",
        label: "生日",
        minWidth: 170,
        align: "center",
        format: (value) => value.toLocaleString("en-US"),
    },
    {
        id: "identityNumber",
        label: "證號",
        minWidth: 170,
        align: "center",
        format: (value) => value.toLocaleString("en-US"),
    },
    {
        id: "email",
        label: "Email",
        minWidth: 170,
        align: "center",
        format: (value) => value.toFixed(2),
    },
];

function QueryUser({ setAlert, fetchUserLists }) {
    const { setLoading } = useContext(UserContext);
    const phoneRef = useRef(null);
    const idRef = useRef(null);
    const emailRef = useRef(null);

    const customerInfoTitles = [
        {
            label: "門號",
            placeholder: "請輸入門號",
            ref: phoneRef,
        },
        {
            label: "證號",
            placeholder: "請輸入證號",
            ref: idRef,
        },
        {
            label: "Email",
            placeholder: "請輸入Email",
            ref: emailRef,
        },
    ];

    const handleClickSearch = async () => {
        if (
            !phoneRef.current.value &&
            !idRef.current.value &&
            !emailRef.current.value
        ) {
            setAlert("輸入框請勿留空");
            return;
        }

        setLoading(true);
        let payload = {};
        if (phoneRef.current.value)
            payload["phoneNumber"] = phoneRef.current.value;
        if (idRef.current.value)
            payload["identityNumber"] = idRef.current.value;
        if (emailRef.current.value) payload["email"] = emailRef.current.value;
        fetchUserLists(payload);
        setLoading(false);
    };

    const setInput = (event, item) => {
        item.ref.current.value = event.target.value;
    };

    const clearInputs = (index) => {
        for (let i = 0; i < customerInfoTitles.length; i++) {
            if (i !== index) {
                customerInfoTitles[i].ref.current.value = null;
            }
        }
    };

    return (
        <Container
            maxWidth='md'
            sx={{ marginTop: "40px" }}
        >
            <Panel>
                <Grid
                    container
                    spacing={2}
                >
                    {customerInfoTitles.map((item, index) => (
                        <Grid
                            item
                            xs={6}
                            display='flex'
                            justifyContent='space-between'
                            alignItems='center'
                            key={index}
                        >
                            <TitleLabel fontSize='15px'>
                                {item.label}
                            </TitleLabel>
                            <Box sx={{ width: "320px" }}>
                                <TextField
                                    fullWidth
                                    width='20px'
                                    placeholder={item.placeholder}
                                    size='small'
                                    onFocus={() => clearInputs(index)}
                                    onChange={(e) => setInput(e, item)}
                                    inputRef={item.ref}
                                ></TextField>
                            </Box>
                        </Grid>
                    ))}
                    <Grid
                        item
                        xs={12}
                    >
                        <Button
                            variant='text'
                            sx={{ float: "right" }}
                            onClick={handleClickSearch}
                        >
                            <SearchIcon fontSize='small' />
                            查詢
                        </Button>
                        <Button
                            variant='text'
                            sx={{ float: "right" }}
                            onClick={() => clearInputs(-1)}
                        >
                            <RestartAltIcon fontSize='small' />
                            重置
                        </Button>
                    </Grid>
                </Grid>
            </Panel>
        </Container>
    );
}

function QueryResult({ userList, setUserData }) {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        userList !== undefined && (
            <Container
                maxWidth='md'
                sx={{ height: "40vh" }}
            >
                <TitleLabel
                    marginTop='20px'
                    marginBottom='10px'
                >
                    查詢結果
                </TitleLabel>
                <Panel>
                    {userList?.length === 0 &&
                        "查無對應資訊，請輸入不同關鍵字搜尋"}
                    {userList === null && "查詢失敗，請重新查詢"}
                    {userList?.length > 0 && (
                        <>
                            <TableContainer
                                sx={{
                                    maxHeight: 300,
                                    width: "100%",
                                    overflow: "hidden",
                                }}
                            >
                                <Table
                                    stickyHeader
                                    aria-label='sticky table'
                                >
                                    <TableHead>
                                        <TableRow>
                                            {columns.map((column) => (
                                                <TableCell
                                                    align={column.align}
                                                    key={column.label}
                                                    style={{
                                                        minWidth:
                                                            column.minWidth,
                                                    }}
                                                >
                                                    {column.label}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {userList
                                            .slice(
                                                page * rowsPerPage,
                                                page * rowsPerPage + rowsPerPage
                                            )
                                            .map((row, index) => {
                                                return (
                                                    <TableRow
                                                        hover
                                                        role='checkbox'
                                                        tabIndex={-1}
                                                        key={row.chineseName}
                                                        onClick={() =>
                                                            setUserData(row)
                                                        }
                                                        sx={{
                                                            cursor: "pointer",
                                                        }}
                                                    >
                                                        {columns.map(
                                                            (column) => {
                                                                const value =
                                                                    row[
                                                                        column
                                                                            .id
                                                                    ];
                                                                return (
                                                                    <TableCell
                                                                        align={
                                                                            column.align
                                                                        }
                                                                        key={
                                                                            columns.label
                                                                        }
                                                                    >
                                                                        {column.format &&
                                                                        typeof value ===
                                                                            "number"
                                                                            ? column.format(
                                                                                  value
                                                                              )
                                                                            : value}
                                                                    </TableCell>
                                                                );
                                                            }
                                                        )}
                                                    </TableRow>
                                                );
                                            })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 100]}
                                component='div'
                                count={userList.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </>
                    )}
                </Panel>
            </Container>
        )
    );
}

export default function CustomerService() {
    const [userData, setUserData] = useState(null);
    const [alert, setAlert] = useState("");
    const [userList, setUserList] = useState(undefined);
    const { state } = useLocation();
    const query = state?.query;


    const fetchUserLists = async (payload) => {
        const response = await CustomerAPI.getUser(payload);

        if (response.length === 1) {
            setUserData(response[0]);
        } else {
            setUserList(response);
        }
    };

    useLayoutEffect(() => {
        if (query) {
            fetchUserLists(query);
        }
    }, [query]);

    return (
        <Authorizer
            permissions={[{ action: "GET", object: "/cms/customer-services" }]}
        >
            {userData ? (
                <CustomerInfo userData={userData} />
            ) : (
                <>
                    <QueryUser
                        setAlert={setAlert}
                        fetchUserLists={fetchUserLists}
                    />
                    <QueryResult
                        userList={userList}
                        setUserData={setUserData}
                    />
                </>
            )}
            <AlertMessage alertState={{ alert, setAlert }} />
        </Authorizer>
    );
}
