import React, { useState, useContext } from "react";
import { Paper, Button, Box, Avatar } from "@mui/material";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { grey } from "@mui/material/colors";

import UserMenu from "./UserMenu";
import { UserContext } from "../UserContext";
import { Label } from "./Label";
import { sideMenuWidth } from "./SideMenu";
export const navigationBarHeight = 50;

export default function NavigationBar() {
    const [userMenu, setUserMenu] = useState(null);
    const { navTitle, user } = useContext(UserContext);

    const sxNavigationBar = {
        position: "fixed",
        top: 0,
        left: `${sideMenuWidth}px`,
        right: 0,
        height: `${navigationBarHeight}px`,
        zIndex: (theme) => theme.zIndex.drawer,
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
    };

    return (
        <Paper
            sx={sxNavigationBar}
            elevation={0}
            square
        >
            <Box
                display='flex'
                alignItems='center'
                flexDirection='row'
                justifyContent='space-between'
                marginLeft='8px'
                marginRight='8px'
                height='100%'
            >
                {/* navigation title */}
                <Box display='flex'>
                    <Label
                        sx={{ marginLeft: "20px" }}
                        fontSize='16px'
                        fontWeight='medium'
                    >
                        {navTitle}
                    </Label>
                    <ArrowRightIcon style={{ color: grey[600] }} />
                </Box>

                {/* <Box></Box> */}
                <UserMenu userMenuState={{ userMenu, setUserMenu }} />

                <Button
                    color='inherit'
                    size='large'
                    startIcon={
                        <Avatar
                            alt=''
                            sx={{
                                width: 20,
                                height: 20,
                                bgcolor: "primary.main",
                            }}
                        />
                    }
                    sx={{
                        textTransform: "none",
                        color: "primary.main",
                        fontWeight: 400,
                    }}
                    onClick={(e) => {
                        setUserMenu(e.currentTarget);
                    }}
                >
                    {user?.email}
                </Button>
            </Box>
        </Paper>
    );
}
