import React from "react";
import { Paper } from "@mui/material";

export default function Panel({ top, bottom, height, gutterBottom, children }) {
    const sxPanel = {
        padding: (theme) => theme.spacing(4),
        borderRadius: "8px",
        marginBottom: gutterBottom ? "8px" : "0px",
        borderColor: "#eee",
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.075)",
    };

    return (
        <Paper
            //   className={classes.paper}
            variant='outlined'
            sx={sxPanel}
        >
            {children}
        </Paper>
    );
}
