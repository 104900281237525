import React from "react";
import { TextField, Tooltip } from "@mui/material";

export default function TextInput({
    label,
    placeholder,
    gutterTop,
    gutterBottom,
    multiline,
    disabled,
    InputProps,
    type,
    size,
    value,
    setValue,
    tip,
}) {
    const sx = {};
    if (gutterTop) sx["marginTop"] = 4;
    if (gutterBottom) sx["marginBottom"] = 4;

    const formatValue = (value) => {
        if (type === "number") {
            return parseInt(value);
        }
        return value;
    };

    const handleTextChange = (event) => {
        const text = formatValue(event.target.value);
        setValue(text);
    };

    const singleLineTextField = () => {
        return (
            <Tooltip
                title={tip}
                arrow
            >
                <TextField
                    sx={sx}
                    label={label}
                    fullWidth
                    placeholder={placeholder}
                    color='secondary'
                    autoComplete='off'
                    value={value ? value : ""}
                    onChange={handleTextChange}
                    InputLabelProps={{ shrink: true }}
                    disabled={disabled}
                    InputProps={InputProps}
                    type={type ? type : undefined}
                    size={size ? size : "normal"}
                />
            </Tooltip>
        );
    };

    const multipleLinesTextField = () => {
        return (
            <Tooltip
                title={tip}
                arrow
            >
                <TextField
                    sx={sx}
                    label={label}
                    fullWidth
                    placeholder={placeholder}
                    color='secondary'
                    autoComplete='off'
                    value={value ? value : ""}
                    onChange={handleTextChange}
                    InputLabelProps={{ shrink: true }}
                    rows={4}
                    multiline
                    InputProps={InputProps}
                    type={type ? type : undefined}
                    size={size ? size : "normal"}
                />
            </Tooltip>
        );
    };

    return <>{multiline ? multipleLinesTextField() : singleLineTextField()}</>;
}
