import { api } from "./configs/axiosConfigs";

export const CustomerAPI = {
    getUser: async function (payload) {
        try {
            const response = await api.request({
                url: "/customer-services/user",
                method: "POST",
                data: payload,
            });
            return response.data;
        } catch (error) {
            return null;
        }
    },
    getUserStatus: async function (id) {
        try {
            const response = await api.request({
                url: `/customer-services/user/${id}/status`,
                method: "GET",
            });
            return response.data;
        } catch (error) {
            return null;
        }
    },
    getOrder: async function (id) {
        try {
            const response = await api.request({
                url: `/customer-services/user/${id}/orders`,
                method: "GET",
            });
            return response.data;
        } catch (error) {
            return null;
        }
    },
    getUserAccounts: async function (id) {
        try {
            const response = await api.request({
                url: `/customer-services/user/${id}/accounts`,
                method: "GET",
            });
            return response.data;
        } catch (error) {
            return null;
        }
    },
    getUserDeposit: async function (id) {
        try {
            const response = await api.request({
                url: `/customer-services/user/${id}/deposit-record`,
                method: "GET",
            });
            return response.data;
        } catch (error) {
            return null;
        }
    },
    getUserWithdraw: async function (id) {
        try {
            const response = await api.request({
                url: `/customer-services/user/${id}/withdraw-record`,
                method: "GET",
            });
            return response.data;
        } catch (error) {
            return null;
        }
    },
    login: async function (payload) {
        try {
            const response = await api.request({
                url: "/customer-services/login",
                method: "POST",
                data: payload,
            });
            return response.data;
        } catch (error) {
            return null;
        }
    },
};
