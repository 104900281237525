import { styled } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:hover":
        {
            outline: "none !important",
            cursor: "pointer",
        },
    "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus":
        {
            outline: "none !important",
        },
}));

export default StyledDataGrid;
