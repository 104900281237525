import { Box } from "@mui/material";
import { useContext, useState } from "react";
import Panel from "../../components/Panel";
import { CustomerAPI } from "../../apis/CustomerAPI";
import { UserContext } from "../../UserContext";
import moment from "moment/moment";
import { useEffectOnce } from "../../utils/UseEffectOnce";
import CustomGrid from "../../components/CustomGrid";

const kycStatus = {
    unverified: "未驗證",
    verifing: "審核中",
    succeed: "審核成功",
    failed: "審核失敗",
};

const kycFailedReason = {
    id_number_exists: "身份證號重複",
    identity_type: "身份類別不符",
    id_type: "第一證不是身份證",
    sales_channel: "不是直營申辦",
    id_1_missing: "沒有第一證件資料",
    id_2_missing: "沒有第二證件資料",
    id_1_verify_failed: "身份証認證失敗",
};

const status = {
    unverified: "未審核",
    verifing: "審核中",
    succeed: "審核成功",
    failed: "審核失敗",
};

export default function CustomerStatus({ userId }) {
    const { setLoading } = useContext(UserContext);
    const [userStatus, setUserStatus] = useState([]);

    useEffectOnce(() => {
        const fetchUserStatus = async () => {
            setLoading(true);
            const response = await CustomerAPI.getUserStatus(userId);
            if (response !== null) {
                setUserStatus([
                    {
                        title: "會員狀態",
                        value: kycStatus[response.kycStatus] || "",
                    },
                    {
                        title: "狀態原因描述",
                        value:
                            kycFailedReason[response.kycFailedReason] ||
                            "已認證",
                    },
                    {
                        title: "帳號建立時間",
                        value: moment
                            .unix(response.createdTime)
                            .format("MM/DD/YYYY HH:mm"),
                    },
                    {
                        title: "銀行帳戶認證狀態",
                        value: status[response.bankAccount?.status] || "",
                    },
                    {
                        title: "綁定銀行",
                        value: response.bankAccount?.bankName,
                    },
                    {
                        title: "綁定帳戶",
                        value: response.bankAccount?.accountNumber,
                    },
                    {
                        title: "綁定申請時間",
                        value: response.bankAccount
                            ? moment
                                  .unix(response.bankAccount.createdTime)
                                  .format("MM/DD/YYYY HH:mm")
                            : "",
                    },
                    {
                        title: "帳戶綁定失敗原因",
                        value: response.bankAccount?.failReason,
                    },
                ]);
            }
            setLoading(false);
        };
        fetchUserStatus();
    }, []);

    return (
        <Box sx={{ marginBottom: 4 }}>
            <Panel>
                <CustomGrid
                    data={userStatus}
                    underline
                />
            </Panel>
        </Box>
    );
}
