import React, { useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { styled } from "@mui/system";
import { UserContext } from "../UserContext";
import Authorizer from "../utils/Authorizer";

import {
    Drawer,
    Box,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
} from "@mui/material";

import HomeIcon from "@mui/icons-material/Home";
import PaidIcon from "@mui/icons-material/Paid";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import GroupIcon from "@mui/icons-material/Group";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import CalculateIcon from "@mui/icons-material/Calculate";

export const sideMenuWidth = 240;
const menuItemHeight = 60;

const sxDrawer = {
    backgroundColor: "#F7F6F3",
    width: `${sideMenuWidth}px`,
};

const sxItem = {
    height: menuItemHeight,
};

const sxItemSelected = {
    height: menuItemHeight,
    backgroundColor: "grey.200",
};

const sxIcon = {
    marginLeft: 2,
    marginRight: -2.5,
    color: "secondary.main",
};

const sxIconSelected = {
    marginLeft: 2,
    marginRight: -2.5,
    color: "primary.main",
};

const sxText = {
    fontWeight: 400,
    variant: "body3",
    color: "secondary.main",
};

const sxTextSelected = {
    fontWeight: 600,
    variant: "body3",
    color: "primary.main",
};

const LogoImage = styled("img")(({ theme }) => ({
    marginTop: "15px",
    marginBottom: "20px",
    width: "65%",
}));

const menuItems = [
    {
        text: "Home",
        icon: <HomeIcon fontSize='small' />,
        path: "/",
        permissions: [{ action: "GET", object: "/cms/admins/me" }],
    },
    {
        text: "AML",
        icon: <VerifiedUserIcon fontSize='small' />,
        path: "/aml",
        permissions: [],
    },
    {
        text: "Accounting",
        icon: <CalculateIcon fontSize='small' />,
        path: "/accounting",
        permissions: [{ action: "GET", object: "/cms/accounting" }],
    },
    {
        text: "Finance",
        icon: <PaidIcon fontSize='small' />,
        path: "/finance",
        permissions: [{ action: "GET", object: "/cms/finance" }],
    },
    {
        text: "Customer Service",
        icon: <SupportAgentIcon fontSize='small' />,
        path: "/customer",
        permissions: [{ action: "GET", object: "/cms/customer-services" }],
    },
    {
        text: "User Management",
        icon: <GroupIcon fontSize='small' />,
        path: "/users",
        permissions: [],
    },
];

export default function SideMenu() {
    const navigate = useNavigate();
    const location = useLocation();
    const { setNavTitle } = useContext(UserContext);

    const selectMenuItem = (item) => {
        navigate(item.path);
        setNavTitle(item.text);
    };

    return (
        <Drawer
            variant='permanent'
            anchor='left'
            PaperProps={{ sx: sxDrawer }}
        >
            <Box
                display='flex'
                justifyContent='center'
            >
                <LogoImage src='../images/logo.png' />
            </Box>

            <List>
                {menuItems.map((item) => (
                    <Authorizer permissions={item.permissions}>
                        <ListItem
                            button
                            key={item.text}
                            onClick={() => selectMenuItem(item)}
                            sx={
                                location.pathname === item.path
                                    ? sxItemSelected
                                    : sxItem
                            }
                        >
                            <ListItemIcon
                                sx={
                                    location.pathname === item.path
                                        ? sxIconSelected
                                        : sxIcon
                                }
                            >
                                {item.icon}
                            </ListItemIcon>

                            <ListItemText
                                primaryTypographyProps={
                                    location.pathname === item.path
                                        ? sxTextSelected
                                        : sxText
                                }
                                primary={item.text}
                            />
                        </ListItem>
                    </Authorizer>
                ))}
            </List>
        </Drawer>
    );
}
