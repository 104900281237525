import { Box } from "@mui/material";
import Panel from "../../components/Panel";
import { useEffectOnce } from "../../utils/UseEffectOnce";
import CustomGrid from "../../components/CustomGrid";

const customerBackgroundTitles = [
    { title: "本次業務往來/目的用途", value: "" },
    { title: "行業別", value: "" },
    { title: "交易方式", value: "" },
    { title: "客戶是否曾更名", value: "" },
    { title: "職務別", value: "" },
    { title: "預計承作交易金額", value: "" },
    { title: "更名前名稱", value: "" },
    { title: "年薪", value: "" },
    { title: "財富來源", value: "" },
    { title: "PEP與客戶之關係", value: "" },
];

export default function CustomerBackground({ userId }) {
    useEffectOnce(() => {
        // fetch data by userId
    }, []);

    return (
        <Box sx={{ marginBottom: 4 }}>
            <Panel>
                <CustomGrid
                    data={customerBackgroundTitles}
                    underline
                />
            </Panel>
        </Box>
    );
}
