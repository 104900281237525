import React from "react";
import { Container } from "@mui/material";
import Panel from "../../components/Panel";
import { Label } from "../../components/Label";

export default function Home() {
    return (
        <>
            <Container
                maxWidth='md'
                sx={{ marginTop: "40px" }}
            >
                <Panel>
                    <Label>Home</Label>
                </Panel>
            </Container>
        </>
    );
}
