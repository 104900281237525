import React from "react";
import { InputLabel, MenuItem, FormControl, Select } from "@mui/material";

export default function Selector({
    label,
    items,
    gutterTop,
    gutterBottom,
    value,
    setValue,
}) {
    const sx = {};
    if (gutterTop) sx["marginTop"] = 4;
    if (gutterBottom) sx["marginBottom"] = 4;

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    return (
        <FormControl
            fullWidth
            color='secondary'
            sx={sx}
        >
            <InputLabel>{label}</InputLabel>
            <Select
                value={value === null ? "" : value}
                label={label}
                color='secondary'
                onChange={handleChange}
            >
                {items.map((item, index) => (
                    <MenuItem
                        value={item.value}
                        key={index}
                    >
                        {item.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}
