import axios from "axios";
import secureLocalStorage from "react-secure-storage";

const baseURL = process.env.REACT_APP_CEX_SERVICE;

export const api = axios.create({
    baseURL: `${baseURL}/cms`,
});

// refresh token
async function getRefreshToken() {
    const refreshToken = secureLocalStorage.getItem("refreshToken");
    try {
        const resp = await axios.post(
            `${baseURL}/cms/admins/refresh`,
            {},
            {
                headers: {
                    accept: "application/json",
                    authorization: `Bearer ${refreshToken}`,
                },
            }
        );

        return resp.data;
    } catch (err) {
        console.error(err);
        return null;
    }
}

// Request interceptor for API calls
api.interceptors.request.use(
    async (config) => {
        const accessToken = secureLocalStorage.getItem("accessToken");
        //console.log("accessToken: " + accessToken);
        if (accessToken) {
            config.headers = {
                Authorization: `Bearer ${accessToken}`,
                Accept: "application/json",
                "Content-Type": "application/json",
            };
        }
        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);

// Response interceptor for API calls
api.interceptors.response.use(
    (response) => {
        // check & store tokens
        const refreshToken = response.data?.tokens?.refreshToken;
        const accessToken = response.data?.tokens?.accessToken;

        if (refreshToken) {
            secureLocalStorage.setItem("refreshToken", refreshToken);
        }

        if (accessToken) {
            secureLocalStorage.setItem("accessToken", accessToken);
        }

        return response;
    },
    async function (error) {
        const statusCode = error.response?.status;
        const responseData = error.response?.data;
        const errorMsg = responseData?.error?.message;

        if (statusCode === 401 && errorMsg === "Invalid authentication.") {
            console.log("Token has been revoked...");

            // refresh tokens
            const tokens = await getRefreshToken();
            if (tokens) {
                const refreshToken = tokens?.refreshToken;
                const accessToken = tokens?.accessToken;

                secureLocalStorage.setItem("refreshToken", refreshToken);
                secureLocalStorage.setItem("accessToken", accessToken);

                // re-send request with new token
                const originalRequest = error.config;
                return api(originalRequest);
            }
        }

        return Promise.reject(error);
    }
);
