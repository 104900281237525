import React, { useContext, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { styled } from "@mui/system";
import { Box } from "@mui/material";

import SideMenu from "./SideMenu";
import NavigationBar from "./NavigationBar";
import { sideMenuWidth } from "./SideMenu";
import { navigationBarHeight } from "./NavigationBar";
import { UserContext } from "../UserContext";

export const layoutPaths = [
    "/",
    "/aml",
    "/finance",
    "/accounting",
    "/customer",
    "/users",
];

const DivRoot = styled("div")(({ theme }) => ({
    display: "flex",
}));

const ContainerBox = styled(Box)(({ theme }) => ({
    width: "100%",
    // backgroundColor: "#000000ff",
    paddingTop: `${navigationBarHeight}px`,
    paddingLeft: `${sideMenuWidth}px`,
}));

const Layout = ({ children }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { user } = useContext(UserContext);

    useEffect(() => {
        if (!user && layoutPaths.includes(location.pathname)) {
            navigate("/login");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location, user]);

    const layoutEnabled = () => {
        return (
            <DivRoot>
                <SideMenu />
                <NavigationBar />
                <ContainerBox> {children}</ContainerBox>
            </DivRoot>
        );
    };

    const layoutDisabled = () => {
        return <> {children} </>;
    };

    return (
        <>
            {layoutPaths.includes(location.pathname)
                ? layoutEnabled()
                : layoutDisabled()}
        </>
    );
};

export default Layout;
