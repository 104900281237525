import { Box } from "@mui/material";
import { useState, useContext } from "react";
import StyledDataGrid from "../../components/StyledDataGrid";
import { UserContext } from "../../UserContext";
import { CustomerAPI } from "../../apis/CustomerAPI";
import moment from "moment/moment";
import { useEffectOnce } from "../../utils/UseEffectOnce";

const columns = [
    {
        field: "method",
        headerName: "類型",
        width: 142,
        headerAlign: "center",
        align: "center",
        sortable: false,
    },
    {
        field: "date",
        headerName: "日期",
        width: 142,
        headerAlign: "center",
        align: "center",
        sortable: false,
    },
    {
        field: "time",
        headerName: "時間",
        width: 142,
        headerAlign: "center",
        align: "center",
        sortable: false,
    },
    {
        field: "amount",
        headerName: "金額",
        width: 142,
        headerAlign: "center",
        align: "center",
        sortable: false,
    },
    {
        field: "status",
        headerName: "狀態",
        width: 142,
        headerAlign: "center",
        align: "center",
        sortable: false,
    },
    {
        field: "note",
        headerName: "備註",
        width: 142,
        headerAlign: "center",
        align: "center",
        sortable: false,
    },
];
const status = {
    done: "已完成",
    succeed: "已放款",
    open: "處理中",
    processing: "處理中",
    rejected: "失敗",
};
const depositMethod = {
    withDraw: "銀行放款",
    bank: "銀行轉帳",
    store: "門市儲值",
    order: "訂單儲值",
};

export default function CustomerDeposit({ userId }) {
    const [depositRecords, setDepositRecords] = useState([]);
    const { setLoading } = useContext(UserContext);

    useEffectOnce(() => {
        const fetchAccounts = async () => {
            setLoading(true);
            const response = await CustomerAPI.getUserDeposit(userId);
            if (response !== null) {
                setDepositRecords(response);
            }
            setLoading(false);
        };

        fetchAccounts();
    }, [setLoading, userId]);

    const rows = depositRecords.map((data, index) => {
        return {
            id: index + 1,
            method: depositMethod[data.depositMethod],
            date: moment.unix(data.createdTime).format("YYYY/MM/DD"),
            time: moment.unix(data.createdTime).format("HH:mm"),
            amount: data.amount,
            status: status[data.status],
            note: "無",
        };
    });

    return (
        <Box sx={{ height: "calc(100vh - 450px)", width: "100%" }}>
            <StyledDataGrid
                rows={rows}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
                disableSelectionOnClick
            />
        </Box>
    );
}
