import { Box, Container, Grid, Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import Panel from "../../components/Panel";
import { TitleLabel, SubTitleLabel } from "../../components/Label";
import PropTypes from "prop-types";
import CustomerBackground from "./CustomerBackground";
import CustomerStatus from "./CustomerStatus";
import CustomerTrade from "./CustomerTrade";
import CustomerAsset from "./CustomerAsset";
import CustomerDeposit from "./CustomerDeposit";
import CustomerWithdraw from "./CustomerWithdraw";

function UserInfo({ value, userData }) {
    const customerInfoTitles = [
        { title: "姓名", value: userData.chineseName },
        { title: "會員UID", value: userData.id },
        { title: "生日", value: userData.birthDate },
        { title: "門號", value: userData.phoneNumber },
        { title: "證號", value: userData.identityNumber },
        { title: "Email", value: userData.email },
        { title: "戶籍地址", value: userData.regAddr },
        { title: "通訊地址", value: userData.comAddr },
    ];
    return (
        <Container
            maxWidth={value === 2 ? "xl" : "md"}
            sx={{ marginTop: "40px" }}
        >
            <Panel>
                <Grid
                    container
                    spacing={2}
                >
                    {customerInfoTitles.map((item, index) => (
                        <Grid
                            item
                            key={index}
                            xs={index < customerInfoTitles.length - 2 ? 6 : 12}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    position: "relative",
                                    alignItems: "center",
                                }}
                            >
                                <TitleLabel fontSize='15px'>
                                    {item.title}
                                </TitleLabel>
                                <SubTitleLabel
                                    sx={{
                                        position: "absolute",
                                        left: "120px",
                                    }}
                                >
                                    {item.value}
                                </SubTitleLabel>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </Panel>
        </Container>
    );
}

function TabPanel({ children, value, index }) {
    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            sx={{ maxWidth: "lg", border: "solid 1px" }}
        >
            {value === index && <>{children}</>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function TabPage({ userData, value, setValue }) {
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const componentLists = [
        {
            title: "基本資料",
            value: <CustomerBackground userId={userData.id} />,
        },
        { title: "身份狀態", value: <CustomerStatus userId={userData.id} /> },
        { title: "交易紀錄", value: <CustomerTrade userId={userData.id} /> },
        { title: "帳戶資產", value: <CustomerAsset userId={userData.id} /> },
        {
            title: "儲值紀錄",
            value: <CustomerDeposit userId={userData.id} />,
        },
        {
            title: "提領紀錄",
            value: <CustomerWithdraw userId={userData.id} />,
        },
    ];

    return (
        <>
            <Container
                maxWidth={value === 2 ? "xl" : "md"}
                sx={{ marginTop: "10px" }}
            >
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label='basic tabs example'
                    >
                        {componentLists.map((item, index) => (
                            <Tab
                                key={item.title}
                                label={item.title}
                                id={`simple-tab-${index}`}
                                aria-controls={`simple-tabpanel-${index}`}
                            />
                        ))}
                    </Tabs>
                </Box>
            </Container>
            <Container
                maxWidth={value === 2 ? "xl" : "md"}
                sx={{ marginTop: "30px" }}
            >
                {componentLists.map((item, index) => (
                    <TabPanel
                        value={value}
                        index={index}
                        key={index}
                    >
                        {item.value}
                    </TabPanel>
                ))}
            </Container>
        </>
    );
}

export default function CustomerInfo({ userData }) {
    const [value, setValue] = useState(0);

    return (
        <>
            <UserInfo
                userData={userData}
                value={value}
            />
            <TabPage
                userData={userData}
                value={value}
                setValue={setValue}
            />
        </>
    );
}
