import React from "react";
import { Container } from "@mui/material";
import Panel from "../../components/Panel";
import { Label } from "../../components/Label";
import Authorizer from "../../utils/Authorizer";

export default function Accounting() {
    return (
        <Authorizer
            permissions={[{ action: "GET", object: "/cms/accounting" }]}
        >
            <Container
                maxWidth='md'
                sx={{ marginTop: "40px" }}
            >
                <Panel>
                    <Label>Accounting</Label>
                </Panel>
            </Container>
        </Authorizer>
    );
}
