import React from "react";
import { Container } from "@mui/material";
import Panel from "../../components/Panel";
import { Label } from "../../components/Label";
import Authorizer from "../../utils/Authorizer";

export default function AML() {
    return (
        <Authorizer permissions={[]}>
            <Container
                maxWidth='md'
                sx={{ marginTop: "40px" }}
            >
                <Panel>
                    <Label>AML</Label>
                </Panel>
            </Container>
        </Authorizer>
    );
}
