import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../UserContext";

export default function Authorizer({ children, permissions }) {
    const { user } = useContext(UserContext);
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        const checkPermissions = async () => {
            if (user) {
                if (permissions.length === 0) {
                    permissions.push({ action: "*", object: "/*" });
                }

                for (const permission of permissions) {
                    let allowed = await user?.authorizer.can(
                        permission?.action,
                        permission?.object
                    );
                    if (allowed) {
                        setVisible(true);
                        return;
                    }
                }
            }

            setVisible(false);
        };

        checkPermissions();
    }, [user, permissions]);

    return <>{visible && children}</>;
}
