import { TitleLabel } from "../../components/Label";
import {
    Box,
    Button,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Table,
} from "@mui/material";
import { useState, useContext } from "react";
import CustomerDialog from "./CustomerDialog";
import Panel from "../../components/Panel";
import { UserContext } from "../../UserContext";
import { CustomerAPI } from "../../apis/CustomerAPI";
import { useEffectOnce } from "../../utils/UseEffectOnce";
import CustomGrid from "../../components/CustomGrid";

export default function CustomerAsset({ userId }) {
    const [openDialog, setOpenDialog] = useState(false);
    const [asset, setAsset] = useState([]);
    const { setLoading } = useContext(UserContext);
    let ntdAsset = 0;
    let digitalAsset = 0;
    let tableData = [];

    asset.totalAssets?.forEach((item) => {
        if (item.currencyId === "TWD") {
            ntdAsset += item.twdValue;
        } else {
            tableData.push({
                cryptoType: item.currencyId,
                amount: item.balance,
                twdValue: item.twdValue,
            });
            digitalAsset += item.twdValue;
        }
    });

    const totalAsset = digitalAsset + ntdAsset;
    const assetData = [
        { title: "帳戶總資產(TWD)", value: totalAsset },
        { title: "台幣帳戶餘額(TWD)", value: ntdAsset },
        { title: "數位資產(TWD)", value: digitalAsset },
        {
            title: "門市入金限額",
            value: `單筆${asset.orderLimitAmount}萬/單月${asset.monthlyLimitAmount}萬`,
        },
        { title: "本月門市入金可用餘額", value: asset.remainingAmount },
    ];

    useEffectOnce(() => {
        const fetchAccounts = async () => {
            setLoading(true);
            const response = await CustomerAPI.getUserAccounts(userId);
            if (response !== null) {
                setAsset(response);
            }
            setLoading(false);
        };

        fetchAccounts();
    }, [setLoading, userId]);

    const handleEvent = () => {
        setOpenDialog(true);
    };
    return (
        <>
            <Panel gutterBottom>
                <CustomGrid data={assetData.slice(0, 3)} />
            </Panel>

            <Panel gutterBottom>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <TitleLabel>數位資產總覽</TitleLabel>
                    <Button
                        variant='text'
                        size='large'
                        onClick={handleEvent}
                    >
                        資產紀錄
                    </Button>
                </Box>
                <Table aria-label='simple table'>
                    <TableHead>
                        <TableRow>
                            <TableCell>幣種</TableCell>
                            <TableCell align='center'>數量</TableCell>
                            <TableCell align='right'>台幣價值</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tableData.map((data) => (
                            <TableRow
                                key={data.cryptoType}
                                sx={{
                                    "&:last-child td, &:last-child th": {
                                        border: 0,
                                    },
                                }}
                            >
                                <TableCell
                                    component='th'
                                    scope='row'
                                >
                                    {data.cryptoType}
                                </TableCell>
                                <TableCell align='center'>
                                    {data.amount}
                                </TableCell>
                                <TableCell align='right'>
                                    {data.twdValue}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Panel>

            <Box sx={{ marginBottom: 4 }}>
                <Panel>
                    <CustomGrid data={assetData.slice(3)} />
                </Panel>
            </Box>

            <CustomerDialog
                openDialog={openDialog}
                setOpenDialog={setOpenDialog}
                monthlyAssets={asset.monthlyAssets}
            />
        </>
    );
}
