import React, { useContext, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
    Box,
    Container,
    Avatar,
    Button,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@mui/material";
import { styled } from "@mui/system";
import { grey } from "@mui/material/colors";
import LockPersonIcon from "@mui/icons-material/LockPerson";
import { UserContext } from "../../UserContext";
import { AdminsAPI } from "../../apis/AdminsAPI";
import { CustomerAPI } from "../../apis/CustomerAPI";
import { useEffectOnce } from "../../utils/UseEffectOnce";

import { Label } from "../../components/Label";
import Panel from "../../components/Panel";
import TextInput from "../../components/TextInput";
import RoundCornerButton from "../../components/Button";
import CopyrightBar from "../../components/CopyrightBar";
import AlertMessage from "../../components/Alert";
import secureLocalStorage from "react-secure-storage";

const casbinjs = require("casbin.js");

const Background = styled("div")(() => ({
    height: "90vh",
    backgroundImage: "url(/images/background.jpg)",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
}));

export default function Login() {
    const navigate = useNavigate();
    const { setLoading, setUser, setNavTitle } = useContext(UserContext);
    const [alert, setAlert] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [openDialog, setOpenDialog] = useState(false);
    const [resetEmail, setResetEmail] = useState("");
    const severity = useRef(null);
    const location = useLocation();

    useEffectOnce(() => {
        const queryUrl = location.search;
        const queryParams = new URLSearchParams(queryUrl);
        const data = queryParams.get("data");

        async function login(data) {
            setLoading(true);

            const response = await CustomerAPI.login({
                data: encodeURIComponent(data),
            });
            const user = response?.admin;

            if (user) {
                const accessToken = secureLocalStorage.getItem("accessToken");
                const baseURL = process.env.REACT_APP_CEX_SERVICE;
                const authorizer = new casbinjs.Authorizer("auto", {
                    endpoint: `${baseURL}/cms/admins/me/policies`,
                    requestHeaders: { Authorization: `Bearer ${accessToken}` },
                });
                await authorizer.setUser(`role:${user.group}`);
                user.authorizer = authorizer;

                setLoading(false);

                setUser(user);

                const query = response?.query;
                if (query !== null) {
                    navigate("/customer", { state: { query } });
                    setNavTitle("Customer Service");
                } else {
                    navigate("/");
                    setNavTitle("Home");
                }
            } else {
                setLoading(false);
                severity.current = "error";
                setAlert("登入資料錯誤或逾期");
                navigate("/login");
            }
        }

        if (data) {
            login(data);
        }
    });

    const handleClickLogin = async () => {
        if (email === "") {
            severity.current = "error";
            setAlert("Please enter email");
        } else if (password === "") {
            severity.current = "error";
            setAlert("Please enter password");
        } else {
            setLoading(true);

            const response = await AdminsAPI.signin({
                email: email,
                password: password,
            });

            const user = response?.admin;
            // console.log("user: ", user);

            if (user) {
                const accessToken = secureLocalStorage.getItem("accessToken");
                const baseURL = process.env.REACT_APP_CEX_SERVICE;
                const authorizer = new casbinjs.Authorizer("auto", {
                    endpoint: `${baseURL}/cms/admins/me/policies`,
                    requestHeaders: { Authorization: `Bearer ${accessToken}` },
                });
                await authorizer.setUser(`role:${user.group}`);
                user.authorizer = authorizer;

                setLoading(false);

                setUser(user);
                navigate("/");
                setNavTitle("Home");
            } else {
                setLoading(false);
                severity.current = "error";
                setAlert("Invalid email or password");
            }
        }
    };

    const handleClickForgetPassword = async () => {
        setResetEmail("");
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleResetPassword = async () => {
        if (resetEmail !== "") {
            setOpenDialog(false);

            setLoading(true);

            const response = await AdminsAPI.forgotPassword({
                email: resetEmail,
            });

            setLoading(false);

            if (response === null) {
                severity.current = "error";
                setAlert("Invalid email address");
            } else {
                severity.current = "success";
                setAlert("We've sent a password reset link to " + resetEmail);
            }
        } else {
            severity.current = "error";
            setAlert("Please enter email");
        }
    };

    const resetEmailDialog = () => {
        return (
            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                maxWidth={"xs"}
                fullWidth={true}
            >
                <DialogTitle>Forget password</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin='dense'
                        id='name'
                        label='Email'
                        fullWidth
                        autoComplete='off'
                        variant='outlined'
                        placeholder='Enter your email'
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => {
                            setResetEmail(e.target.value);
                        }}
                    />
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleCloseDialog}>Cancel</Button>
                    <Button onClick={handleResetPassword}>
                        Reset password
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    return (
        <Background>
            <Box
                display='flex'
                justifyContent='center'
                sx={{ marginTop: "50px" }}
            >
                <Avatar
                    sx={{
                        width: 50,
                        height: 50,
                        bgcolor: "#FF8E1533",
                    }}
                >
                    <LockPersonIcon color='primary' />
                </Avatar>
            </Box>

            <Label
                fontSize='25px'
                sx={{
                    marginTop: "18px",
                    marginBottom: "18px",
                    textAlign: "center",
                    color: (theme) => theme.palette.secondary.main,
                }}
            >
                Login
            </Label>

            <Container maxWidth='xs'>
                <Panel>
                    <TextInput
                        label='Email'
                        placeholder='Enter your email'
                        gutterBottom
                        value={email}
                        setValue={setEmail}
                    />

                    <TextInput
                        label='Password'
                        placeholder='Enter password'
                        gutterBottom
                        type='password'
                        value={password}
                        setValue={setPassword}
                    />

                    <Button
                        variant='text'
                        fullWidth
                        color='secondary'
                        style={{ fontSize: "10px", color: grey[400] }}
                        sx={{
                            textTransform: "none",
                            marginTop: "-20px",
                            marginBottom: "30px",
                        }}
                        onClick={handleClickForgetPassword}
                    >
                        Forget password
                    </Button>

                    <RoundCornerButton onClick={handleClickLogin}>
                        Login
                    </RoundCornerButton>
                </Panel>
            </Container>

            <CopyrightBar />

            {/* Alert */}
            <AlertMessage
                alertState={{ alert, setAlert, severity: severity.current }}
            />

            {resetEmailDialog()}
        </Background>
    );
}
